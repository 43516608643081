import React from 'react'
import { graphql } from 'gatsby'
import { Layout } from '../components/Layout'
import { Link, useIntl } from "gatsby-plugin-intl"

import HomeIcon from '../components/icons/homeIcon'

// markup
const NotFoundPage = ({data }) => {
  const intl = useIntl()
  return (
    <Layout data={data}>

      <div className="error404">

        <div className="container">
          <h1 className="error404__title">{intl.formatMessage({id: 'error404_head'})}</h1>

          <p className="error404__subtext" dangerouslySetInnerHTML={{ __html: intl.formatMessage({id: 'error404_text'})} }></p>

          <Link to={"/"} className="link-plain"><HomeIcon />{intl.formatMessage({id: 'error404_btntext'})}</Link>

        </div>

      </div>

    </Layout>
  )
}

export default NotFoundPage

export const query = graphql`
  query NotFoundPageQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
